import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { links } from '@app/shared/constants/auth.constants';
import { environment } from '@env/environment';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  popupVisible: boolean = false;
  linkToOpen: string | null = null;
 
  links = [
    { text: 'Privacy policy', href: links.privacyPolicy },
    { text: 'Terms of use', href: links.termsOfUse },
    { text: 'Cookie preferences', href: 'javascript:void(0);', type: 'cookieBtn' },
    { text: 'Accessibility', href: links.accessibility }
  ];
  imgLink: string = links.imgLink;
  copyrightText ='';
  siteNotice ='This site is intended only for residents of the United States and its territories, including Puerto Rico.';

  ngOnInit(): void {
    this.copyrightText = `Copyright © ${new Date().getFullYear()} Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved.`;
    this.loadScript();
  }

  customizeMySettings():void{
      //  this.document.getElementById("onetrust-pc-btn-handler").click();
      window.location.href = "javascript:Optanon.ToggleInfoDisplay()";
  }

  loadScript() {
    const script = document.createElement('script');
    script.src = links.cookieScript;
    script.type = "text/javascript";
    script.charset = "UTF-8";
    script.setAttribute("data-domain-script", environment.dataDomainScript);
    document.head.appendChild(script);
  }
  
  openLink(url: string): void {
    window.open(url, '_blank');
}
  showPopup(link: string): void {
    this.linkToOpen = link;
    this.popupVisible = true;
  }

  closePopup(): void {
    this.popupVisible = false;
    this.linkToOpen = null;
  }

  proceed(): void {
    if (this.linkToOpen) {
      window.open(this.linkToOpen, '_blank');
    }
    this.closePopup();
  }
}