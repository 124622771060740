import { IPrintSpec } from '@app/shared/models/print-config';

export const printSpecs: IPrintSpec[] = [
  {
    print_spec_code: 'PST06',
    spec_name: 'Poster #6',
    pages: 'N/A',
    flat_size: '11 x 17',
    finished_size: '11 x 17',
    inks: '4cp/0',
    stock: '4 mil repositional vinyl',
    paper_manufacturer: 'Synaps Removable - 5mil',
    bind: 'trim',
    unit_of_measure: '1 each',
  },
  {
    print_spec_code: 'PST07',
    spec_name: 'Poster #7',
    pages: 'N/A',
    flat_size: '17 x 24',
    finished_size: '17 x 24',
    inks: '4cp/0',
    stock: '4 mil repositional vinyl',
    paper_manufacturer: 'Synaps Removable - 5mil',
    bind: 'trim',
    unit_of_measure: '1 each',
  },
  {
    print_spec_code: 'TP001',
    spec_name: 'Single-sided Tearpad',
    pages: 'N/A',
    flat_size: '8.5 X 11',
    finished_size: '8.5 X 11',
    inks: '4cp/0',
    stock: '80# gloss text',
    paper_manufacturer: 'Program Digital stock - U2',
    bind: 'trim; collate 25 leaves with chipboard backer; glue along the 8.5 inch way',
    unit_of_measure: '1 tear pad of 25',
  },
  {
    print_spec_code: 'TP002',
    spec_name: 'Double-sided Tearpad (Portrait)',
    pages: 'N/A',
    flat_size: '8.5 X 11',
    finished_size: '8.5 X 11',
    inks: '4cp/4cp',
    stock: '80# gloss text',
    paper_manufacturer: 'Program Digital stock - U2',
    bind: 'trim; collate 25 leaves with chipboard backer; glue along the 8.5 inch way',
    unit_of_measure: '1 tear pad of 25',
  },
  {
    print_spec_code: 'TP003',
    spec_name: 'Double-sided Tearpad (Landscape)',
    pages: 'N/A',
    flat_size: '11 x 8.5',
    finished_size: '11 x 8.5',
    inks: '4cp/4cp',
    stock: '80# gloss text',
    paper_manufacturer: 'Program Digital stock - U2',
    bind: 'trim; collate 25 leaves with chipboard backer; glue along the 11 inch way',
    unit_of_measure: '1 tear pad of 25',
  },
  {
    print_spec_code: 'TP005',
    spec_name: 'Double-sided Tearpad Uncoated (Portrait)',
    pages: 'N/A',
    flat_size: '8.5 X 11',
    finished_size: '8.5 X 11',
    inks: '4cp/4cp',
    stock: '80# uncoated text',
    paper_manufacturer: 'Program Digital stock - (Lynx)',
    bind: 'trim; collate 25 leaves with chipboard backer; glue along the 8.5 inch way',
    unit_of_measure: '1 tear pad of 25',
  },
  {
    print_spec_code: 'TP008',
    spec_name: 'Double-sided Tearpad (Portrait)',
    pages: '15 (4-page brochures)',
    flat_size: '17 x 11',
    finished_size: '8.5 x 11',
    inks: '4cp/4cp',
    stock: '80# gloss text',
    paper_manufacturer: 'Program Digital stock - U2',
    bind: 'trim, score, and fold 15 - 4pagers; STACK 15 - 4pagers with chipboard backer; glue along the 8.5 inch dimension at head',
    unit_of_measure: '1 tear pad of 15',
  },
  {
    print_spec_code: 'TP009',
    spec_name:
      'Double-sided Tearpad Uncoated (Portrait)   Premade white diecut double wing easel affix to standard chipboard backer',
    pages: 'N/A',
    flat_size: '8.5 X 11',
    finished_size: '8.5 X 11',
    inks: '4cp/4cp',
    stock: '80# uncoated text',
    paper_manufacturer: 'Program Digital stock',
    bind: 'trim; collate 15 leaves with chipboard backer, affix white double wing easel and pad glue at head ( along the 8.5 inch dimension)',
    unit_of_measure: '1 tear pad of 15 convert this data to json',
  },
];
