import { Component, inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute } from '@angular/router';
import { DesignService } from '@app/shared/services/design.service';
import { Store } from '@ngrx/store';
import {
  loadSourceTree,
  loadTargetTree,
} from '@app/shared/store/actions/standardise-design.actions';
import {
  selectSourceTree,
  selectTargetTree,
  selectTargetDesignStructure,
} from '@app/shared/store/selectors/design.selector';
import { TreeStructureComponent } from '../tree-structure/tree-structure.component';
import { StatusDialogComponent } from '../status-dialog/status-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DesignPropertiesComponent } from './components/design-properties/design-properties.component';
import { SpinnerComponent } from '../../../../shared/components/spinner/spinner.component';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { FormsModule } from '@angular/forms';
import { FileService } from '../../services/file.service';

interface TreeNode {
  name: string;
  children?: TreeNode[];
  isDropPlaceholder?: boolean;
  showAddButton?: boolean;
  files?: File[];
  droppedFileName?: string;
  uploadedFileName?: string;
}

@Component({
  selector: 'app-standardise-designs',
  standalone: true,
  imports: [
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    TreeStructureComponent,
    MatDialogModule,
    StatusDialogComponent,
    SpinnerComponent,
    FormsModule
  ],
  templateUrl: './standardise-designs.component.html',
  styleUrl: './standardise-designs.component.scss',
})
export class StandardiseDesignsComponent implements OnInit {
  private readonly store = inject(Store<any>);
  private readonly designService = inject(DesignService);
  private readonly spinnerService = inject(SpinnerService);
  private readonly route = inject(ActivatedRoute);
  dialog = inject(MatDialog);

  leftTreeControl = new NestedTreeControl<TreeNode>((node) => node.children);
  rightTreeControl = new NestedTreeControl<TreeNode>((node) => node.children);
  leftDataSource = new MatTreeNestedDataSource<TreeNode>();
  rightDataSource = new MatTreeNestedDataSource<TreeNode>();
  draggedNode: TreeNode | null = null;
  showAddFolderForm = false;
  currentParentNode: TreeNode | null = null;
  jobCode = 'US-HEQ-2025';
  showSplitScreen = false;
  targetStructure: any;
  designData: any;

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.jobCode = params.get('job_code') ?? '';
      if (this.jobCode) {
        this.fetchTreeData();
      }
    });
    this.store.select(selectTargetDesignStructure).subscribe((data: any) => {
      this.designData = data;
    })
  }

  fetchTreeData() {
    this.store.dispatch(loadSourceTree({ folder: this.jobCode }));
    this.store.dispatch(loadTargetTree({ folder: this.jobCode }));
    this.store.select(selectSourceTree).subscribe((sourceTree: TreeNode[]) => {
      const source = this.rightDataSource;
      source.data = sourceTree;
      this.rightDataSource = source;
      this.rightTreeControl.dataNodes = sourceTree;
      this.rightTreeControl.expandAll();
    });

    this.store.select(selectTargetTree).subscribe((targetTree: TreeNode[]) => {
      const target = this.leftDataSource;
      target.data = targetTree;
      const prevExpansionModel = this.leftTreeControl.expansionModel.selected;
      console.log(prevExpansionModel, 'prev');
      this.leftDataSource = target;
      this.leftTreeControl.dataNodes = targetTree;
      prevExpansionModel.forEach((object) =>
        this.leftTreeControl.expand(object)
      );
      this.leftTreeControl.expandAll();
    });

    this.store.select(selectTargetDesignStructure).subscribe((data: any) => {
      this.targetStructure = data;
      console.log('design: ', data);
    });
  }

  onSourceClick() {
    this.showSplitScreen = true;
  }

  private isNodeEqual(node1: TreeNode, node2: TreeNode): boolean {
    return (
      node1.name === node2.name &&
      node1.droppedFileName === node2.droppedFileName
    );
  }

  displayProperties() {
    const dialogRef = this.dialog.open(DesignPropertiesComponent, {
      backdropClass: 'custom-dialog',
      disableClose: true,
      data: {
        jobCode: this.jobCode,
        selectedNode: null,
        formType: 'design',
        designData:this.designData,
      },
      width: '600px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  onSave() {
    this.spinnerService.show();
    this.targetStructure = { ...this.targetStructure, method_code: '3' };
    this.designService
      .saveFolderStructure(this.targetStructure)
      .subscribe(() => {
        this.openDialog('Design saved', 'Design saved successfully!');
        this.spinnerService.hide();
      });
  }

  goBack() {
    window.history.back();
  }

  onStandardize() {
    this.spinnerService.show();
    console.log('Before standardization:', this.targetStructure);
    this.targetStructure = {
      ...this.targetStructure,
      status: 'STANDARDIZED',
      method_code: '3',
    };
    console.log('After standardization:', this.targetStructure);

    this.designService.saveFolderStructure(this.targetStructure).subscribe({
      next: () => {
        this.spinnerService.hide();
        console.log('Design saved successfully');

        this.openDialog(
          'Design standardized',
          'The design has been standardized!'
        );
      },
      error: (err) => {
        this.spinnerService.hide();
        console.error('Error saving the design:', err);
        console.log('Request body sent:', this.targetStructure);
      },
    });
  }

  openDialog(title: string, message: string): void {
    this.dialog.open(StatusDialogComponent, {
      backdropClass: 'custom-dialog',
      data: { title, message },
      width: '500px',
    });
  }

  hasChild = (_: number, node: TreeNode) =>
    !!node.children && node.children.length > 0;

  jobCodeToBeUploaded: string = '';
  fileName: string = '';
  fileService = inject(FileService);
    onFileSelected(event: any) {
      const file = event.target.files[0];
      const filePath = `${this.jobCodeToBeUploaded}/source/${this.fileName}`
      this.fileService.upload(file, filePath);
    }
}
