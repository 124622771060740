<div class="job-code-header">
  <span>{{ jobCode }}</span>
  <span class="header-buttons">
    <button mat-button class="properties-button" (click)="displayProperties()">Properties</button>
    <button mat-button class="save-button" (click)="onSave()">Save</button>
    <button mat-button class="cancel-button" (click)="goBack()">Cancel</button>
  </span>
</div>
<app-spinner></app-spinner>
<div [class]="'split-screen'">
  <div class="left-pane">
    <span class="header">Standard folder view</span>
    <app-tree-structure
      [treeControl]="leftTreeControl"
      [dataSource]="leftDataSource"></app-tree-structure>
  </div>
  <!--  @if (showSplitScreen) {-->
  <div class="right-pane">
    <div class="d-flex justify-content-between align-items-center">
      <span class="header">Design Bundle</span>
      <button mat-button class="standardize-button" (click)="onStandardize()">
        Standardize
      </button>
    </div>
    <app-tree-structure
      [treeControl]="rightTreeControl"
      [dataSource]="rightDataSource"></app-tree-structure>
  </div>
  <!--  }-->
</div>

<input [(ngModel)]="jobCodeToBeUploaded" placeholder="Job Code"/>
<input [(ngModel)]="fileName" placeholder="File Name"/>

<div>
  <button mat-icon-button (click)="fileInput.click()">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M9.375 7.44772H11.107V15.3766C11.107 15.4798 11.1914 15.5641 11.2945 15.5641H12.7008C12.8039 15.5641 12.8883 15.4798 12.8883 15.3766V7.44772H14.625C14.782 7.44772 14.8687 7.26725 14.7727 7.14538L12.1477 3.82194C12.1301 3.79953 12.1077 3.78141 12.0821 3.76894C12.0565 3.75648 12.0285 3.75 12 3.75C11.9715 3.75 11.9435 3.75648 11.9179 3.76894C11.8923 3.78141 11.8699 3.79953 11.8523 3.82194L9.22734 7.14304C9.13125 7.26725 9.21797 7.44772 9.375 7.44772ZM20.5781 14.6735H19.1719C19.0688 14.6735 18.9844 14.7579 18.9844 14.861V18.4704H5.01562V14.861C5.01562 14.7579 4.93125 14.6735 4.82812 14.6735H3.42188C3.31875 14.6735 3.23438 14.7579 3.23438 14.861V19.5016C3.23438 19.9165 3.56953 20.2516 3.98438 20.2516H20.0156C20.4305 20.2516 20.7656 19.9165 20.7656 19.5016V14.861C20.7656 14.7579 20.6812 14.6735 20.5781 14.6735Z" fill="black" fill-opacity="0.45"/>
    </svg>
  </button>
  <input type="file" #fileInput (change)="onFileSelected($event)" style="display: none;" />
</div>